
export const reviews = [
    {
        id: 1,
        name: "Mehul Adlakha",
        date: "1 Month",
        image: "https://neverowned.in/cdn/shop/files/unnamed_8cd72bbb-c3b4-45fe-aefd-75d19f64e3a0.png?v=1710153374",
        rating: 5,
        review: `bought washing machine from neverowned for the first time. It was good purchase. I will recommend to my relatives as well. Will buy more house hold products  😊`
    },
    {
        id: 2,
        name: "Mee Walee",
        date: "2 Weeks",
        image: "https://neverowned.in/cdn/shop/files/unnamed_1cdc4a16-7556-45a2-9af7-6fb12506a80e.png?v=1710153525",
        rating: 5,
        review: `Recently went to the shop to buy an AC had an amazing overall experience the staff were very professional and the quality of the product was great!`
    },
    {
        id: 3,
        name: "Deepak Gautam",
        date: "1 Month",
        image: "https://neverowned.in/cdn/shop/files/unnamed_419e9061-fc5b-4290-a961-f34ecdee639a.png?v=1710153760",
        rating: 5,
        review: `I purchased a washing machine a best deal I got best price from every where thank you sales team`
    },
    {
        id: 5,
        name: "Krishan Kumar",
        date: "4 Month",
        image: "https://neverowned.in/cdn/shop/files/unnamed_f8f6a541-76a3-47e9-8b82-c734d031e047.png?v=1710153185",
        rating: 5,
        review: `Very  Good and nice service i'm very happy thank u never owner`
    },
    {
        id: 6,
        name: "Tarun Jain",
        date: "4 Month",
        image: "https://neverowned.in/cdn/shop/files/unnamed_abca662b-93bd-4667-af9b-50c554af6347.png?v=1710153425",
        rating: 5,
        review: `I purchased a tv in lowest price smart tv wich is in heavy discount I got I meet here vaibhav nice person`
    },
    {
        id: 7,
        name: "Harsh Choudary",
        date: "3 Month",
        image: "https://neverowned.in/cdn/shop/files/unnamed_1bf26adf-6957-486d-98c3-47054743724e.png?v=1710153978",
        rating: 5,
        review: `I have purchased voltas washing machine at best prize in India. Really recommend everyone to visit the store.`
    },
    {
        id: 8,
        name: "Jaspreet Singh",
        date: "3 Month",
        image: "https://neverowned.in/cdn/shop/files/unnamed_e5a1ca6e-46dc-47d8-9f72-08d14aedbbf4.png?v=1710154247",
        rating: 5,
        review: `I purchased a washing machine top load in lowest price  wich is in heavy discount I got I meet here Dheeraj nice person.`
    },
    {
        id: 9,
        name: "Mukesh Bhatiya",
        date: "2 Month",
        image: "https://neverowned.in/cdn/shop/files/unnamed_10be8287-8523-4554-8c7a-98fd1593bd0c.png?v=1710154336",
        rating: 5,
        review: `Unbeatable Price range Discount on every Product at NeverOwned thanks to Sale Team mr Dheeraj.`
    },
    {
        id: 10,
        name: "Rakesh Kaushik",
        date: "2 Month",
        image: "https://neverowned.in/cdn/shop/files/unnamed_b6e29021-0f03-472d-8af4-ee1b8e7d7414.png?v=1710154459",
        rating: 5,
        review: `I am very happy my product and price this store is great I meet Sumit very nice person`
    },
];