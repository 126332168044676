export default function OurProcess() {
    return <>
        <img
            src="/images/our-process.webp"
            alt="our-process"
            style={{
                width: '100%',
                height: 'auto'
            }}
            className='d-block'
        />
    </>
}